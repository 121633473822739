import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { UTEXCompanyService } from 'src/app/u2-utex/services/company.service';
import { UTEXDocumentGroupMappingService } from 'src/app/u2-utex/services/document-group-mapping.service';
import { UTEXDocumentGroupService } from 'src/app/u2-utex/services/document-group.service';
import { UTEXErrorCodeService } from 'src/app/u2-utex/services/error-code.service';
import { UTEXErrorResolutionReasonMappingService }
  from 'src/app/u2-utex/resolution-and-reason-mapping/error-resolution-reason-mapping.service';
import { MassResolutionService } from 'src/app/u2-utex/mass-resolution/mass-resolution.service';
import { UTEXReasonService } from 'src/app/u2-utex/utex-common-resolution/reason.service';
import { UTEXRecordTypeService } from 'src/app/u2-utex/utex-common-resolution/edit-modal/record-type.service';
import { ResolutionStatusMappingService } from 'src/app/u2-utex/utex-common-resolution/resolution-status-mapping.service';
import { UTEXStatusService } from 'src/app/u2-utex/utex-common-resolution/status.service';
import { UTEXRecordDTOService } from 'src/app/u2-utex/services/utex-record-dto.service';
import { AuthAllocComponentService } from 'src/app/u2-person/csc/auth-allocation/auth-allocation.service';
import { CCHHistoryService } from 'src/app/u2-shared/cch-history/cch-history.service';
import { QueuePageService } from 'src/app/u2-utex/queue/queue.service';
import { CommonPageService } from 'src/app/u2-utex/utex-common-resolution/utex-common-resolution.service';


@Injectable({
  providedIn: 'root',
})

export class CDCNCompanyService implements OnDestroy {

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private readonly cchHistoryService: CCHHistoryService,
    private readonly authAllocComponentService: AuthAllocComponentService,
    private readonly commonPageService: CommonPageService,
    private readonly queuePageService: QueuePageService,
    private readonly utexDocumentGroupService: UTEXDocumentGroupService,
    private readonly utexDocumentGroupMappingService: UTEXDocumentGroupMappingService,
    private readonly utexErrorCodeService: UTEXErrorCodeService,
    private readonly utexStatusService: UTEXStatusService,
    private readonly utexReasonService: UTEXReasonService,
    private readonly utexRecordTypeService: UTEXRecordTypeService,
    private readonly utexErrorResolutionReasonService: UTEXErrorResolutionReasonMappingService,
    private readonly utexRecordDTOService: UTEXRecordDTOService,
    private readonly MassResolutionService: MassResolutionService,
    private readonly gridService: CommonPageService,
    private readonly resolutionStatusMappingService: ResolutionStatusMappingService,
    private readonly companyService: UTEXCompanyService,
  ) {}

  public clearControllers(): void {
    this.cchHistoryService.clear();
    this.authAllocComponentService.clear();
    this.commonPageService.clear();
    this.queuePageService.clear();
    this.utexDocumentGroupMappingService.clear();
    this.utexDocumentGroupService.clear();
    this.utexErrorCodeService.clear();
    this.utexStatusService.clear();
    this.utexReasonService.clear();
    this.utexRecordTypeService.clear();
    this.utexErrorResolutionReasonService.clear();
    this.utexRecordDTOService.clear();
    this.MassResolutionService.clear();
    this.gridService.clear();
    this.resolutionStatusMappingService.clear();
    this.companyService.clear();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
