import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PeopleFunctionService } from 'src/app/core/services/peopleapi/peoplefunctionapi.services';
import { ILookupValueDto } from 'src/app/core/services/utexapi/utexapi.services';
import { IDocumentSummary } from 'src/app/shared/constants/interface-constants';

export interface ICommonSearchParameters {
  errorCode?: ILookupValueDto;
  status?: ILookupValueDto;
  docGroup?: IDocumentSummary[];
  docId?: number;
  caregiver?: { caregiverId: string };
  participant?: { participantId: string };
  tcn?: string;
}

export interface ICommonSearchResult {
  error?: string;
  errorCodeId?: number;
  status?: number;
  caregiverId?: string;
  caregiverName?: string;
  participantId?: string;
  participantName?: string;
  einId?: string;
  source?: number;
  sourceType?: string;
  exTranId?: string;
  serviceDate?: Date;
  timeIn?: string;
  timeOut?: string;
  serviceCode?: string;
  billUnits?: number;
  payUnits?: number;
  qa?: number;
  ua?: number;
  utexId?: number;
  allowShiftSplit?: boolean;
  historyExist?: boolean;
  notesExist?: boolean;
  exceptionId?: number;
  documentId?: number;
  tcn?: string;
  providerNumber?: string;
}

export interface ICommonResolutionParameters {
  resolution?: ILookupValueDto;
  reason?: ILookupValueDto;
}

@Injectable({
  providedIn: 'root',
})
export class CommonPageService implements OnDestroy {
  private readonly _entity: BehaviorSubject<ICommonSearchResult[]> = new BehaviorSubject<ICommonSearchResult[]>([]);
  private readonly _parameters: BehaviorSubject<ICommonSearchParameters> = new BehaviorSubject<ICommonSearchParameters>({
    errorCode: null,
    status: null,
    docGroup: [],
    docId: null,
    caregiver: null,
    participant: null,
  });

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public toastr: ToastrService,
    public apiService: PeopleFunctionService,
  ) {}

  public getSearchParameters(): Observable<ICommonSearchParameters> {
    return this._parameters;
  }

  public setSearchParameters(data: ICommonSearchParameters) {
    this._parameters?.next(data);
  }

  public getSearchResults(): Observable<ICommonSearchResult[]> {
    return this._entity;
  }

  public setSearchResults(data: ICommonSearchResult[]) {
    this._entity.next([...data]);
  }

  public clear(): void {
    this.clearEntity();
    this.clearParameters();
  }

  public clearEntity(): void {
    this._entity?.next([]);
  }

  public clearParameters(): void {
    this._parameters?.next({
      errorCode: null,
      status: null,
      docGroup: [],
      docId: null,
      caregiver: null,
      participant: null,
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
