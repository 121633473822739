import { Injectable, OnDestroy } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IController } from 'src/app/controllers/interface/IController.interface';
import { PeopleFunctionService } from 'src/app/core/services/peopleapi/peoplefunctionapi.services';
import {
  HttpFilterRequest,
  UTEXService,
  UtexRecordDto,
  UtexRecordsRequest
} from 'src/app/core/services/utexapi/utexapi.services';
import { CommonFunctions } from 'src/app/shared/utilities/common-functions';
import { KendoMasterGridProperties } from 'src/app/shared/utilities/kendo-properties';

/*
Index Use
  0 - Utex View Queues Grid
  1 - Utex Mass Res Caregiver Lookup
  2 - Utex Mass Res Participant Lookup
  3 - Utex Mass Res Grid
*/


export interface UtexRecordDTORequest {
  companyCodeId?: number[];
  utexId?: number;
  status?: number[];
  docType?: number[];
  docId?: number[];
  caregiverId?: string[];
  caregiverFirstName?: string;
  caregiverLastName?: string;
  participantId?: string[];
  participantFirstName?: string;
  participantLastName?: string;
  state?: State;
  pageSize?: number;
}


@Injectable({
  providedIn: 'root',
})

export class UTEXRecordDTOService implements OnDestroy, IController {
  private _entity: BehaviorSubject<UtexRecordDto[]>[] = [
    new BehaviorSubject<UtexRecordDto[]>(
      undefined
    ),
  ];

  private _parameters: BehaviorSubject<UtexRecordDTORequest>[] = [
    new BehaviorSubject<UtexRecordDTORequest>(
      undefined
    ),
  ];

  private _load: BehaviorSubject<boolean>[] = [
    new BehaviorSubject<boolean>(
      false
    ),
  ];

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public toastr: ToastrService,
    public apiService: PeopleFunctionService,
    public commonFunctions: CommonFunctions,
    public utexService: UTEXService,
  ) {}


  private checkIndex(index: number) {
    if (this._entity[index] === undefined ) {
      this._entity[index] = new BehaviorSubject<UtexRecordDto[]>(
        undefined
      );
    }
    if (this._parameters[index] === undefined ) {
      this._parameters[index] = new BehaviorSubject<UtexRecordDTORequest>(
        undefined
      );
    }
    if (this._load[index] === undefined ) {
      this._load[index] = new BehaviorSubject<boolean>(
        undefined
      );
    }
  }

  async search(data?: UtexRecordDTORequest, index = 0) {
    this.checkIndex(index);
    if (data) {
      this._parameters[index]?.next(data);
    } else if (this._parameters[index].getValue()) {
      data = this._parameters[index].getValue();
    } else {
      return;
    }

    data = this.commonFunctions.removeNull(data);
    if (data?.caregiverId && !Array.isArray(data.caregiverId)) {
      data.caregiverId = [data.caregiverId];
    }
    if (data?.participantId && !Array.isArray(data.participantId)) {
      data.participantId = [data.participantId];
    }
    this._load[index]?.next(true);
    const request: UtexRecordsRequest = new UtexRecordsRequest();
    request.filter =  new HttpFilterRequest();
    request.careFirst = data.caregiverFirstName;
    request.careIds = data.caregiverId;
    request.careLast = data.caregiverLastName;
    request.ccIds = data.companyCodeId;
    request.dtIds = data.docType;
    request.docIds = data.docId;
    request.filter.sortCol = undefined;
    request.filter.sortDir = undefined;
    request.filter.filterCol = undefined;
    request.filter.filterVal = undefined;
    request.filter.size = data?.pageSize ?? KendoMasterGridProperties.pageSize;
    request.filter.page = undefined;
    request.partFirst = data.participantFirstName;
    request.partIds = data.participantId;
    request.partLast = data.participantLastName;
    request.staIds = data.status;
    request.errorCodes = undefined;
    request.utexId = data.utexId;
    this.utexService.getUtexRecords(request).pipe(
        take(1),
        takeUntil(this._destroying$)
    )
    .subscribe({
      next: (res) => {
        if (res) {
          this._entity[index]?.next(res);
        }
      },
      error: error => {
        this.commonFunctions.handleErrorResponse(error, this.toastr);
      },
      complete: () => {
        this._load[index]?.next(false);
      },
    });
  }

  async create(data?: UtexRecordDto, index = 0) {
    this._load[index]?.next(true);
    this.utexService.createUtexRecords(
      [data]
    )
    .pipe(
        take(1),
        takeUntil(this._destroying$)
    )
    .subscribe({
      next: (res) => {
      },
      error: error => {
        this.commonFunctions.handleErrorResponse(error, this.toastr);
      },
      complete: () => {
        this._load[index]?.next(false);
      },
    });
  }

  async update(data?: UtexRecordDto, index = 0) {

    this._load[index]?.next(true);
    this.utexService.updateUtexRecords(
      [data]
    )
    .pipe(
        take(1),
        takeUntil(this._destroying$)
    )
    .subscribe({
      next: (res) => {
        if (res) {
          this.toastr.success('UTEX Record Successfully Updated');
        }
      },
      error: error => {
        this.commonFunctions.handleErrorResponse(error, this.toastr);
      },
      complete: () => {
        this._load[index]?.next(false);
      },
    });

  }

  public fetchData(override = false, index = 0): Observable<UtexRecordDto[]> {
    this.checkIndex(index);
    if (this._entity[index].getValue() === undefined || override) {
      this.search(undefined, index);
    }
    return this._entity[index];
  }

  public getSearchParameters(index = 0): Observable<UtexRecordDTORequest> {
    this.checkIndex(index);
    return this._parameters[index];
  }

  public setSearchParameters(data: UtexRecordDTORequest, index = 0) {
    this.checkIndex(index);
    this._parameters[index]?.next(data);
  }

  public getLoad(index = 0): Observable<boolean> {
    this.checkIndex(index);
    return this._load[index];
  }

  public clear(index = 0): void {
    this._entity[index]?.next(undefined);
    this._parameters[index]?.next(undefined);
  }

  public clearEntity(index = 0): void {
    this._entity[index]?.next(undefined);
  }

  public clearParameters(index = 0): void {
    this._parameters[index]?.next(undefined);
  }

  public clearLoad(index = 0): void {
    this._load[index]?.next(false);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
