import { Injectable, OnDestroy, signal, WritableSignal } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IController } from 'src/app/controllers/interface/IController.interface';
import { IMassResolutionDTO, UTEXService } from 'src/app/core/services/utexapi/utexapi.services';
import { AuthorizationConstants } from 'src/app/shared/constants/authorization-constants';
import { CommonFunctions } from 'src/app/shared/utilities/common-functions';

export interface IMassResolutionRequest {
  companyCode: string;
  errorCodeIds: number[];
  statusIds: number[];
  docGroupIds: number[];
  exceptionId: number;
  caregiverId: string;
  participantId: string;
  documentId: number;
  tcn: string;
}

@Injectable({
  providedIn: 'root',
})

export class MassResolutionService implements OnDestroy, IController {
  private readonly _entity: BehaviorSubject<IMassResolutionDTO[]>
    = new BehaviorSubject<IMassResolutionDTO[]>(
      []
    );
  private readonly _parameters: BehaviorSubject<IMassResolutionRequest>
    = new BehaviorSubject<IMassResolutionRequest>(
      {
        companyCode: null,
        errorCodeIds: [],
        statusIds: [],
        docGroupIds: [],
        exceptionId: null,
        caregiverId: null,
        participantId: null,
        documentId: null,
        tcn: null,
      }
    );
  private readonly _load: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private readonly _tcn: WritableSignal<string> = signal<string>('');

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public toastr: ToastrService,
    public utexService: UTEXService,
    private readonly commonFunctions: CommonFunctions
  ) { }

  async search() {
    if (!this._parameters.getValue()) {
      return;
    }

    const data = this._parameters.getValue();
    this._load.next(true);
    this.utexService.getMassResolutions(
      data.companyCode ?? AuthorizationConstants.companyCode,
      data.errorCodeIds,
      data.statusIds,
      data.docGroupIds,
      data.exceptionId ?? undefined,
      data.caregiverId ?? undefined,
      data.documentId ?? undefined,
      data.participantId ?? undefined,
      data.tcn ?? undefined
    ).pipe(
      take(1),
      takeUntil(this._destroying$)
    ).subscribe({
      next: (res) => {
        if (res) {
          res.forEach(x => x.providerNumber = x.providerNumber.trim());
          this._entity.next([...res]);
        }
      },
      error: error => {
        this.commonFunctions.handleErrorResponse(error, this.toastr);
      },
      complete: () => {
        this._load.next(false);
      },
    });
  }

  public fetchData(override = false): Observable<IMassResolutionDTO[]> {
    if (this._entity.getValue() === undefined || this._entity.getValue().length === 0 || override) {
      this.search();
    }
    return this._entity;
  }

  public getSearchParameters(): Observable<IMassResolutionRequest> {
    return this._parameters;
  }

  public setSearchParameters(data: IMassResolutionRequest) {
    this._parameters.next(data);
  }

  public setTcn(tcn: string) {
    this._tcn.set(tcn);
  }

  public getTcn() {
    return this._tcn;
  }

  public getLoad(): Observable<boolean> {
    return this._load;
  }

  public clear(): void {
    this.clearEntity();
    this.clearParameters();
  }

  public clearEntity(): void {
    this._entity.next([]);
  }

  public clearParameters(): void {
    this._parameters.next({
      companyCode: null,
      errorCodeIds: [],
      statusIds: [],
      docGroupIds: [],
      exceptionId: null,
      caregiverId: null,
      participantId: null,
      documentId: null,
      tcn: null,
    });
  }

  public clearLoad(): void {
    this._load.next(false);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
