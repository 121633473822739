import { DatePipe, DecimalPipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalGuard, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { UserIdleModule } from 'angular-user-idle';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConstants } from './core/appConstant';
import { AccessDeniedInterceptor } from './core/interceptors/AccessDeniedInterceptor';
import { CustomHttpInterceptorService } from './core/interceptors/CustomHttpInterceptorService';
import { TokenInterceptor } from './core/interceptors/tokenInterceptor';
import { AccessRightsRouteGurd } from './core/routegurds/accessrightsroutegurd';
import { AppInsightsService } from './core/services/app-insights.service';
import { AUTHORIZATION_API_BASE_URL } from './core/services/authorizationapi/authorizationapi.services';
import { BILLING_API_BASE_URL } from './core/services/billingapi/billingfunctionapi.services';
import { CONFIGURATION_API_BASE_URL } from './core/services/configurationapi/configurationfunctionapi.services';
import { DateConverterService } from './core/services/dateconverter.service';
import { ErrorHandlerService } from './core/services/error-handler.service';
import { FALLOUT_API_BASE_URL } from './core/services/falloutapi/falloutfunctionapi.services';
import { INTFRAMEWORK_API_BASE_URL } from './core/services/intframeworkapi/intframeworkapi.services';
import { NOTE_API_BASE_URL } from './core/services/noteapi/notefunctionapi.services';
import { NOTIFICATION_API_BASE_URL } from './core/services/notificationsapi/notificationfunctionapi.services';
import { PAYROLL_API_BASE_URL } from './core/services/payrollapi/payrollfunctionapi.services';
import { PEOPLE_API_BASE_URL } from './core/services/peopleapi/peoplefunctionapi.services';
import { API_BASE_URL } from './core/services/u2api.services';
import { PortalUserService } from './core/services/userservice.service';
import { UTEX_API_BASE_URL } from './core/services/utexapi/utexapi.services';
import { EmptyComponent } from './empty/empty.component';
import { MainBreadcrumbComponent } from './main-breadcrumb/main-breadcrumb.component';
import { CDCNCompanyService } from './main-header/cdcn-company-service/cdcn-company.service';
import { MainHeaderComponent } from './main-header/main-header.component';
import { MorecompaniesLookupComponent } from './main-header/morecompanies-lookup/morecompanies-lookup.component';
import { MainSidenavComponent } from './main-sidenav/main-sidenav.component';
import { NavItemComponent } from './main-sidenav/nav-item/nav-item.component';
import { CommonDropdownService } from './shared/services/common-dropdown.service';
import { DatePickerCommonService } from './shared/services/date-picker-common.service';
import { CommonFunctions } from './shared/utilities/common-functions';
import { U2SharedModule } from './u2-shared/u2-shared.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { USERS_API_BASE_URL } from './core/services/usersapi/usersapi.services';
import { ROLES_API_BASE_URL } from './core/services/rolesapi/rolesapi.services';
import { HierarchicalPermissionsService } from './core/services/hierarchical-permissions.service';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const scopesToConsent = environment.apiScope;

@NgModule({
  declarations: [
    AppComponent,
    MainHeaderComponent,
    MainSidenavComponent,
    MainBreadcrumbComponent,
    EmptyComponent,
    UnauthorizedComponent,
    MorecompaniesLookupComponent,
    NavItemComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        authority: 'https://login.microsoftonline.com/' + environment.tenant,
        clientId: environment.clientId,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: environment.cacheLocation,
        storeAuthStateInCookie: isIE,
      }
    }), {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          extraScopesToConsent: [scopesToConsent],
          prompt : 'select_account'
        }
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map ([
      ])
    }),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: 'CDMS Devtools',
      maxAge: 25,
      logOnly: environment.production as any,
      connectInZone: true
    }),
    SortableModule,
    GridModule,
    ExcelModule,
    SortableModule,
    InputsModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
      enableHtml: true,
    }),
    ReactiveFormsModule,
    DialogModule,
    UserIdleModule.forRoot({idle: AppConstants.sessionTimeOut, timeout: AppConstants.sessionLogOut, ping: AppConstants.keepAliveIntervel}),
    TooltipModule,
    DropDownsModule,
    U2SharedModule,
    LayoutModule,
    IconsModule
  ],

  providers: [
    DatePipe,
    DecimalPipe,
    CommonFunctions,
    MsalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AccessRightsRouteGurd,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessDeniedInterceptor,
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: API_BASE_URL,
      useFactory: () => environment.apiHost
    },
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptorService, multi: true },
    PortalUserService,
    DateConverterService,
    AppInsightsService,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    DatePickerCommonService,
    CommonDropdownService,
    CDCNCompanyService,
    {
      provide: BILLING_API_BASE_URL,
      useFactory: () => environment.billingApiHost
    },
    {
      provide: NOTIFICATION_API_BASE_URL,
      useFactory: () => environment.notificationApiHost
    },
    {
      provide: PAYROLL_API_BASE_URL,
      useFactory: () => environment.payrollApiHost
    },
    {
      provide: PEOPLE_API_BASE_URL,
      useFactory: () => environment.peopleApiHost
    },
    {
      provide: ROLES_API_BASE_URL,
      useFactory: () => environment.rolesApiHost
    },
    {
      provide: USERS_API_BASE_URL,
      useFactory: () => environment.usersApiHost
    },
    {
      provide: UTEX_API_BASE_URL,
      useFactory: () => environment.utexApiHost
    },
    {
      provide: AUTHORIZATION_API_BASE_URL,
      useFactory: () => environment.authorizationApiHost
    },
    {
      provide: CONFIGURATION_API_BASE_URL,
      useFactory: () => environment.configurationApiHost
    },
    {
      provide: FALLOUT_API_BASE_URL,
      useFactory: () => environment.falloutApiHost
    },
    {
      provide: NOTE_API_BASE_URL,
      useFactory: () => environment.noteApiHost
    },
    {
      provide: UTEX_API_BASE_URL,
      useFactory: () => environment.utexApiHost
    },
    {
      provide: INTFRAMEWORK_API_BASE_URL,
      useFactory: () => environment.intframeworkApiHost
    },
    HierarchicalPermissionsService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
