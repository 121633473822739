export class ValidationErrorExpressionConstants {
    public static readonly idNotFound = 'Could not find a user by the specified name.';
    public static readonly invalidIdErrorMessage = 'Please enter a name or Solomon/Employee ID (7 digit number).';
    public static readonly invalidDateRangeErrorMessage = 'Please specify a valid date range.';
    public static readonly authorizationDashboardSearchbyParameterNoRecordsErrorMessage
        = 'No authorization exists for given search criteria';
    public static readonly authorizationDashboardSearchbyDates = 'End date cannot be before Start date';
    public static readonly authorizationDashboardCopyAuthErrorMessage = 'Please select an authorization record to copy';
    public static readonly noRecordsAvailableErrorMessage = 'No Records Available';
    public static readonly transactionLogFail = 'Cannot delete agreement due to linked transaction or vendor records';

    public static readonly authorizationDashboardCopyAuthMultiSelect = 'Please select only one authorization record at a time to copy';

    public static readonly kendoGridFilterOnlybyOneColumnErrorMessage = 'Please select only one column at a time to filter';

    public static readonly kendoGridSortOnlybyOneColumnErrorMessage = 'Please select only one column at a time to sort';

    public static readonly unAuthorize401ErrorMessage = 'You are not authorized to perform the requested operation';
    public static readonly deleteRoleSuccess = 'Role(s) are deleted successfully';

    public static readonly programLookupSelectCriteriaValidationMessage
        = 'Please assign Client Id prior selecting Provider Lookup';

    public static readonly programFieldSelectCriteriaValidationMessage
        = 'Please set Client Id prior selecting Provider field';

    public static readonly pcnInProgressErrorMessage = 'A batch containing PCN records with status "InProgress" cannot be created';
    public static readonly excludedPcnsOnlyErrorMessage
        = 'A batch cannot be created while the "Display Excluded Items Only" checkbox is selected.';
    public static readonly bcnLockedErrorMessage = 'Some or all of the BCNs of this batch have already been locked';
    public static readonly pcnLockedErrorMessage = 'Some or all of the PCNs of this batch have already been locked';
    public static readonly bcnLockedAdjustmentErrorMessage = 'Some or all of the Shifts selected for adjustment have already been locked';
    public static readonly serverErrorMessage = 'An unexpected server error occurred. Please contact system administrator';
    public static readonly MonthlyHoursAllocation = 'MonthlyUnitsAllocation';
    public static readonly ProgramServiceTypeError = 'Selected Service type is already added with Program Type.';
    public static readonly ExceededWWL = 'ExceededWWL';
    public static readonly authorizationCopySelect = 'Please select an Auth to copy';

    public static readonly authorizationCopySelectOnlyOne = 'You can select only one auth at a time to copy';

    public static readonly authorizationCopySaveBeforeCopy = 'Selected auth needs to be saved first before using copy functionality';

    public static readonly clientIDNotFoundCleared = 'Client ID not-found/cleared';

    public static readonly clientLastNameNotFoundCleared = 'Client Name not-found/cleared';

    public static readonly clientInsuredIdNotFoundCleared = 'Insured Id not-found/cleared';

    public static readonly programNotFoundCleared = 'Program not-found/cleared';

    public static readonly diagnosisCodeNotFoundCleared = 'Diagnosis Code not-found/cleared';

    public static readonly externalCaseManagerNotFoundCleared = 'External Case Manager not-found/cleared';

    public static readonly serviceCodeNotFoundCleared = 'Service Code not-found/cleared';

    public static readonly serviceCodeNotFoundError =
        'There are no available service codes for this Program currently set up in Payer Rates';

    public static readonly serviceTypeNotFoundCleared = 'Service Type not-found/cleared';

    public static readonly employeeNotFoundCleared = 'Employee not-found/cleared';

    public static readonly cgvEmpMappingNotFoundCleared = 'Caregiver Employer Relationship not-found/cleared';

    public static readonly vendorNotFoundCleared = 'Vendor not-found/cleared';

    public static readonly payorNotFoundCleared = 'Payor not-found/cleared';

    public static readonly remarkCodeNotFoundCleared = 'Remark Code not-found/cleared';

    public static readonly adjustmentCodeNotFoundCleared = 'Adjustment Code not-found/cleared';

    public static readonly CcnNotFoundCleared = 'CCN not-found/cleared';

    public static readonly diagCodeNotFoundError = 'DX Code Entered Does Not Exist';

    public static readonly woNotFoundCleared = 'Write-Off not-found/cleared';

    public static readonly batchNotFoundCleared = 'Batch not-found/cleared';

    public static readonly EdiNotFoundCleared = 'Edi Configuration not-found/cleared';

    public static readonly ProgramText = ' Program(s)';

    public static readonly AuthNotFoundCleared = 'Authorization not-found/cleared';

    public static readonly glaccountNotFoundCleared = 'GL Account not-found/cleared';

    public static readonly userNotFoundCleared = 'User not-found/cleared';

    public static readonly hcpcsCodeNotFoundCleared = 'HCPCS Code not-found/cleared';

    public static readonly payerNotFoundCleared = 'Payer not-found/cleared';

    public static readonly fundingTypeNotFoundCleared = 'Funding type not-found/cleared';

    public static readonly fundingSourceNotFoundCleared = 'Funding source not-found/cleared';

    public static readonly programTypeNotFoundCleared = 'Program type source not-found/cleared';

    public static readonly defaultDiagnosisCodeNotFoundCleared = 'Default diagnosis code source not-found/cleared';

    public static readonly receiverNotFoundCleared = 'Receiver source not-found/cleared';

    public static readonly onlydraftedAuthorizationDelete = 'Only drafted Authorization[s] can be deleted';

    public static readonly atleastServiceCodeRequiredMessage = 'At least a Service code is required';

    public static readonly unitAmountRequiredMessage = 'Unit/Amount Range is required';

    public static readonly unitRangeRequiredMessage = 'Unit Range is required';

    public static readonly expAccountNotFoundCleared = 'Exp Account not-found/cleared';

    public static readonly revAccountNotFoundCleared = 'Account not-found/cleared';

    public static readonly feeServiceCodeNotFoundCleared = 'Fee Service Code not-found/cleared';

    public static readonly caregiverNotFoundCleared = 'Caregiver not-found/cleared';

    public static readonly participantNotFoundCleared = 'Participant not-found/cleared';

    public static readonly eorNotFoundCleared = 'Employer Of Record not-found/cleared';

    public static readonly insuredIdNotFoundCleared = 'Insured Id of Record not-found/cleared';

    public static readonly countrySelectionMessage = 'Select Country';

    public static readonly onlyActiveParticipantInsuranceDelete = 'Only Active Participant Insurance(s) can be deleted';

    public static readonly onlyActiveECMDelete = 'Only Active External Case Manager(s) can be deleted';

    public static readonly searchAdjustmentParticipantIdRequired = 'Participant Id is required';

    public static readonly authorizationDashboardNoDeleteAuthSelectErrorMessage = 'Please select an authorization record to delete';

    public static readonly rangeUnitRequiredMessage = 'Range Units is required';

    public static readonly rangeAmountRequiredMessage = 'Range Amount is required';

    public static readonly rangeUnitGreaterThanZeroMessage = 'Range Units must be greater than zero';

    public static readonly rangeAmountGreaterThanZeroMessage = 'Range Amount must be greater than zero';

    public static readonly evvAddressRequired = 'address is required';

    public static readonly billCodeNotFoundCleared = 'Bill Code not-found/cleared';

    public static readonly feeCodeNotFoundCleared = 'Fee Code not-found/cleared';

    public static readonly OutTimeGreaterRequired = 'Out time must be after In time';

    public static readonly FileContentEmpty = 'File content should not be empty';

    public static readonly ErrorInSubmitShift = 'Error in submitting Shift.';

    public static readonly CargiverIdRequired = 'Cargiver Id is required';

    public static readonly MaxBatchSizeExceeded = 'Max Batch Size Exceeded. Max Batch: ';

    public static readonly EmployerIdRequired = 'Employer Id is required';

    public static readonly ParticipantIdRequired = 'Participant Id is required';

    public static readonly CaregiverEmployerRelationshipRequired = 'Caregiver Employer Relationship is required';

    public static readonly GrossHourTotalRequired = 'Gross Hour Total is required';

    public static readonly MileageRequired = 'Mileage is required';

    public static readonly TcnRequired = 'TCN is required';

    public static readonly ServiceCodeRequired = 'Service code is required';

    public static readonly ServiceCodeMayNotHaveAuthOrPayRate =
      'Some of these records may not have an authorization or pay rate for this Service Code.';

    public static readonly UnitRequired = 'Unit is required';

    public static readonly ServiceDateRequired = 'Service date is required';

    public static readonly ServiceDateInvalidFormat = 'ServiceDate is not a valid date format {MM/dd/yyyy}';

    public static readonly ServiceDateInvalid = 'ServiceDate is not a valid date';

    public static readonly InTimeRequired = 'In Time is required';

    public static readonly InTimeInvalid = 'In Time is not a valid time format {23:59}';

    public static readonly OutTimeRequired = 'Out Time is required';

    public static readonly OutTimeInvalid = 'Out Time is not a valid time format {23:59}';

    public static readonly CsvInvalid = 'Please upload valid.csv file.';

    public static readonly CsvReadErrorOccured = 'Error has occured while reading file';

    public static readonly CsvFileFormat = 'Please select.csv file format';

    public static readonly InvalidFile =
      'File not valid. File exceeds maximum rows of {5000}. Resubmit with a .csv file with less than {5000} rows.';

    public static readonly NoDataToInject = 'There are no records to add. Please choose a valid file with data.';

    public static readonly ShiftInjectSubmitMsg1 = 'This action will add ';

    public static readonly ShiftInjectSubmitMsg2 = ' record(s) into the system, do you want to continue?';

    public static readonly NoBcnsToProcess = 'There are no BCN(s) to Process ';

    public static readonly NoPCNsToProcess = 'There are no PCN(s) to Process ';

    public static readonly NoProgConfigSelected = 'No Default Configuration is selected';

    public static readonly NoProgConfigExist = 'No default Program Configuration exists for this Program';

    public static readonly NoProgConfigExistDates = 'No Program Configuration exists for this Program for the given dates.';

    public static readonly NoPrgConfigExistForSelectedPrograms = 'No default Program Configuration exists for ';

    public static readonly FileValidationSuccess = 'File validated successfully!';

    public static readonly ChooseFile = 'Choose file to upload';

    public static readonly batchIDNotFoundCleared = 'Batch ID not-found/cleared';

    public static readonly GridEmpty = ' There must be some data in grid ';

    public static readonly ApprovedBatchDeleteError = 'Only batches that are not approved can be deleted.';

    public static readonly DeletedBatchDeleteError = 'Only batches that are not deleted can be deleted.';

    public static readonly ProcessBatchDeleteError =
      'One or more selected batch(es) is currently processing. Please wait until processing has completed.';

    public static readonly BatchProcessingError = 'Batch {} is currently processing.  Please wait until processing has completed.';

    public static readonly SelectBatchToDeleteError = 'Please select at least one Payment Batch to delete.';

    public static readonly SelectMultiPaymentTODeleteError = 'Please select at least one payment to delete';

    public static readonly OnlyInprogressAndRejecetedbatchSubmit = 'Only in progress / rejected batch can be submitted.';

    public static readonly AppliedAmountValidationError =
        'The applied amount must be as follows: Applied Amount = Check Amount - Adjusted Amount - Interest - Unapplied amount';

    public static readonly RequiredFieldValidationBatchError =
        'Check Number, Check Date, Dep Ref Number, Remit Number are mandatory for submitting the Batch';

    public static readonly InvalidBatchError = 'Invalid Batch!';

    public static readonly RequiredReasonError = 'Reason is required';

    public static readonly AtleastOnPaymentError = 'Please select at least one payment to process';

    public static readonly AppliedAmountGreaterThanCurrentAmtError = 'Applied amount cannot be greater than current amount';

    public static readonly AppliedAmountGreaterThanRemainingAmtError =
      'Applied amount cannot be greater than remaining amount  for Underpayment';

    public static readonly AppliedAmountLessThanRemainingAmtError = 'Applied amount cannot be less than remaining amount for Overpayment';

    public static readonly AppliedAmountNegativeError = 'Must enter a positive number greater than 0';

    public static readonly AppliedAmountPositiveError = 'Must enter a negative number less than 0';

    public static readonly NoItemSelectedError = 'Selected items does not have a valid applied amount';

    public static readonly RowLockedError = 'Editing is not allowd on Locked Rows';

    public static readonly ValidAmountError = 'Please enter a valid Total payment';

    public static readonly ValidAppliedAmountError = 'Please enter a valid Applied Amount';

    public static readonly AppliedAmountGreaterThanUnappliedAmtError = 'Total payment cannot be greater than unapplied amount for batch';

    public static readonly AppliedAmountGreaterThanAvailableAmtError =
        'Original amount cannot be greater than amount available for participant';

    public static readonly ActiveServiceTypeDeleteError = 'Only Active Service Type(s) can be deleted';

    public static readonly AtleastOneServiceTypeError = 'Please select at least one Service Type to delete';

    public static readonly AtleastOneServiceCodeCopyError = 'Please select at least one Service code to copy';

    public static readonly OneAtaTimeServiceCodeToCopyError = 'Please select only one Service code at a time to copy';

    public static readonly ActiveServiceCodeDeleteError = 'Only Active Service code(s) can be deleted';

    public static readonly AtleastOneServiceCodeDeleteError = 'Please select at least one Service code to delete';

    public static readonly NoDataToExport = 'No data to export';

    // eslint-disable-next-line max-len
    public static readonly TooLargeDataError = 'Search results are too large to download to format. Please narrow your search and try again.';

    public static readonly DefaultTemplateOverride = 'Saving the changes will override the previous default template.';

    public static readonly InvalidDateRange = 'Date range cannot be greater than 30 days.';

    public static readonly InvalidEndDate = 'End date cannot be less than Start date.';

    public static readonly EmptyQualificationType = 'Please select Qualification Type';

    public static readonly EmptyStartDate = 'Please select a Start Date.';

    public static readonly EmptyRelatedParticipant = 'Please select a Participant.';

    public static readonly EmptyExpirationDate = 'Please select an Expiration Date.';

    public static readonly InvalidStartDate = 'New start date cannot be later than old start date.';

    public static readonly LaterStartDate = 'Start Date cannot be later than Expiration Date.';

    public static readonly EarlierExpirationDate = 'Expiration Date cannot be earlier than Start Date.';

    public static readonly EarlierOverrideDate = 'Override Date cannot be earlier than Start Date.';

    public static readonly EqualExpirationDate = 'Expiration Date should be greater than Start Date.';

    public static readonly EndDateRequired = 'End Date is mandatory and should not be future date.';

    public static readonly StartDateRequired = 'Start Date is mandatory and should not be future date.';

    public static readonly MinDateExceeded = 'The Entered Date cannot come before 01/01/1900.';

    public static readonly MaxDateExceeded = 'The Entered Date cannot come after 06/06/2079.';

    public static readonly InvalidDateFormat = 'Date format is invalid.';

    public static readonly mcnNotFoundCleared = 'MCN not-found/cleared';

    public static readonly ReclassifyAmountValidationError = 'Reclassify amount cannot be greater than unapplied amount';

    public static readonly TotalAppliedAmountValidationError = 'The total applied amount must be less than unapplied amount';

    public static readonly CheckAmountValidationError = 'Check Amount must be greater than Applied Amount';

    public static readonly EmployerNotFoundCleared = 'Employer not-found/cleared';

    public static readonly PCNotFoundCleared = 'Coordinator not-found/cleared';

    public static readonly InsuredIdNotAlign = 'Insured ID does not align with program and/or service dates';

    public static readonly BalancedBatch = 'Batch is balanced.';

    public static readonly UnBalancedBatch = 'Batch is not balanced.';

    public static readonly representativeNotFoundCleared = 'Representative not-found/cleared';

    public static readonly SelectCustIdorRepId = 'Please select Participant ID or Representative ID';

    public static readonly CaseManagerIDNotFoundCleared = 'Case Manager ID not-found/cleared';

    public static readonly RelatedPersonIDNotFoundCleared = 'Related Person ID not-found/cleared';

    public static readonly SelectCustIdorCmId = 'Please select Participant ID or Case Manager ID';

    public static readonly AppliedAmountGreaterThanOriginalAmtError = 'Total Applied amount cannot be greater than Original amount';

    public static readonly AuthorizationServiceDateOverlapping =
        'There is an overlapping with future authorization. Please update the service dates';

    public static readonly AuthorizationServiceDeleteNotAllowed =
        'Cannot delete service detail due to linked transaction or vendor records';

    public static readonly AuthorizationServiceTransactionNotExist =
        'Transaction Log Records Exist not Covered by Authorization Changes';

    public static readonly AuthorizationFeeNotSetup = 'Service Code is not set up as a Fee';

    public static readonly DeductionTypeNotFoundCleared = 'Deduction type not-found/cleared';

    public static readonly ReclassifyAmountGTzeroError = 'The reclassify amount must be greater than 0.';

    public static readonly MultipleAuthCodeExists = 'Multiple Auth exists, ';

    public static readonly AtleastOneMcnRequired = 'Select at least one MCN to export';

    public static readonly AtleastOneCcnRequired = 'Select at least one CCN to export';

    public static readonly HoldState = 'Some of the selected items are in Hold state.';

    public static readonly TimeValidation = 'Time out must be greater than Time in';

    public static readonly RefreshStatus = 'None of the record(s) refreshed';

    public static readonly OnHold = 'Some of the transactions are part of batch and cannot be put on hold.';

    public static readonly Paid = 'Some of the transactions are marked as paid or billed and cannot be put on hold';

    public static readonly OnUnHold = 'Only hold transactions can be unheld';

    public static readonly TimeAdjust = 'Time can be adjusted only for single record';

    public static readonly MilesAdjust = 'Miles can be adjusted only for single record';

    public static readonly OwnerSelectionRequired = 'Please select an Owner.';

    public static readonly AppliedAmountgtCurrentAmount = 'Some of the applied amounts are greater than current amount';

    public static readonly EmployerDateOverlapError = 'Employer already exists with overlapping dates';

    public static readonly CustomerEmployeeRelationSelect = 'Please select at least one Customer Employee relation to delete';

    public static readonly SelectParticipantOrCaseManager = 'Please select Participant Id or Case Manager Id';

    public static readonly PtpCmgrRelationOverlap = 'Participant CaseManager Relation(s) cannot have overlapping values';

    public static readonly PtpCmgrRelationAuthExternalIdOverlap = 'A relation with the same Auth External Id already exists.';

    public static readonly PtpCmgrRelationDeletedSuccessfully = 'Participant Case Manager Relation(s) deleted successfully';

    public static readonly ParticipantCaseManagerRelationSelect = 'Please select at least one Participant Case Manager relation to delete';

    public static readonly shiftSummaryError = 'An error occured. Unable to fetch Shift Summary. Please try again later';

    public static readonly FilterNeededError = 'Please add a filter to narrow your results';

    public static readonly CCHNotfound = 'An error occured. Unable to fetch CCH. Please try again later';

    public static readonly NegativeAmountCanNotBeAppliedOnMessage =
      'Negative amount(s) can not be applied on Billed, Denied and Adjusted LCN(s)';

    public static readonly AdjustmentCodeDeleteSelectError = 'Please select at least one Adjustment Code to delete';

    public static readonly AdjustmentCodeDeleteActivetError = 'Only Active Adjustment Code can be deleted';

    public static readonly AdjustmentCodeDeleteSuccess = 'Adjustment Code(s) deleted successfully!';

    public static readonly AdjustmentCodeAddedSuccess = 'Adjustment Code added successfully!';

    public static readonly AdjustmentCodeUpdatedSuccess = 'Adjustment Code Updated successfully!';

    public static readonly ValidCustomDates = 'Please select valid dates';

    public static readonly ValidSearchCriteriaRequired = 'Please enter a valid search criteria.';

    public static readonly ecnupdatedSuccessfully = 'ECN(s) Updated Successfullly!';

    public static readonly ecnupdateerror = 'Unable to Updated ECN(s)!';

    public static readonly RequiredFieldValidationError = 'One or more required field is missing or invalid. Please correct and try again.';

    public static readonly ConfirmPeriod = 'Please confirm the current period';

    public static readonly ConfirmRate = 'Please confirm the current rate';

    public static readonly ConfirmModifier = 'Please confirm the current modifier';

    public static readonly ConfirmQualification = 'Please confirm the current qualification';

    public static readonly PeriodOverlap = 'Overlapping Career Hour Period, Please give another value.';

    public static readonly EmployerPayRatePeriodOverlap = 'Overlapping Employer Pay Rate Hour Period, Please give another value.';

    public static readonly EmployerPayRateZeroNegative =
      'Employer Pay Rate is less than or equal to zero dollars. Please assign a rate higher than 0.';

    public static readonly HourStartLessThanEnd = 'Cumulative Career Hour Start should be less than Cumulative Career Hour End.';

    public static readonly HourOverlap = 'Overlapping Cumulative Career Hours, Please give another value.';

    public static readonly DistinctQualification = 'Qualification must have distinct values';

    public static readonly DistinctDifferential = 'Differential must have distinct values';

    public static readonly confirmCurrentEventMessage = 'Confirm the current Event Message Mapping';

    public static readonly confirmCurrentAuthAllocation = 'Confirm the current Auth Allocation';

    public static readonly confirmCurrentEmployerPayRate = 'Confirm the current Employer Pay Rate';

    public static readonly PrevMonthAuthAllocation = 'Previous Month(s) Auth Allocation cannot be modified';

    public static readonly authAllocationsSaved = 'Auth Allocations Saved Successfully!';

    public static readonly NotificationMessageDeleteSelectError = 'Please select at least one Notification Message to delete';

    public static readonly NotificationMessageDeletSuccess = 'Notification Message(s) deleted successfully!';

    public static readonly NotificationMessageAddedSuccess = 'Notification Message added successfully!';

    public static readonly NotificationMessageUpdatedSuccess = 'Notification Message Updated successfully!';

    public static readonly NotificationMessageSentSuccess = 'Notification submitted successfully!';

    public static readonly EventMessageMappingSuccess = 'Messages Mapped to Event Successfully!';

    public static readonly NotificatiosUpdatedSuccess = 'Notification(s) Updated successfully!';

    public static readonly WorkWeekTimeClaimedError =
      'Caregivers time claimed for the Work Week is greater than the Work Week Limit entered.';

    public static readonly ProcessedWWLReevaluation = 'Time already processed will be not be re-evaluated for WWL.';

    public static readonly DateOverlap = 'Cannot Overlap Dates';

    public static readonly WWLDateOverlap = 'WWL date ranges should not overlap';

    public static readonly PermanentWWLCheck =
      'Current Permanent Work Week Limit cannot be accepted, as one or more Temporary Work Week Limits is having equal or lower value.';

    public static readonly CCNAdjustRequest = 'Request for CCN adjustment created successfully!';

    public static readonly ParticipantTaskDateOverlap = 'Participant Task cannot have overlapping values';

    public static readonly EmployerPayRateSuccess = 'Employer pay rates Added/Updated successfully!';

    public static readonly EmployerPayRateDeleteSuccess = 'Employer pay rates deleted successfully!';

    public static readonly svcCodeAuth = 'Cannot create more than one {svcCode} authorization';

    public static readonly IAuthDateOverlap = 'Internal Authorization cannot have overlapping values';

    public static readonly IAuthTransDateOverlap = 'Auth Dates cannot be updated to exclude linked transactions';

    public static readonly NonTrainingEquivalentError = 'Non training qualifications can have only non-trainings equivalents.';

    public static readonly TrainingEquivalentError = 'Training qualifications can have only Trainings equivalents.';

    public static readonly inTimeOutTimeInvalidDifference = 'InTime and OutTime must be in 15 minute increment';

    public static readonly TimeOutOfOriginalTimeRangeError = 'Shift 1 time out and shift 2 time in must be within original time range.';

    public static readonly InvalidNegativeAmount = 'Invalid Negative amount(s).';

    public static readonly MissingInternalAuthData = 'Add internal authorization failed, missing units or service code.';

    public static readonly EmployerOfRecordEmployerSaveNotAllowed =
      'Linking Employers to Employer of Record is not allowed for this company.';

    public static readonly ExternalKeyInsertSuccess = 'External key saved successfully !';

    public static readonly ExternalKeyDeleteSuccess = 'External key deleted successfully !';

    public static OverBudgetUnits = 'OverBudgetUnits';

    public static RangeOverBudgetUnits = 'RangeOverBudgetUnits';

    public static OkToWork = 'OkToWork';

    public static Exceeded24Hours = 'Exceeded24Hours';

    public static OverInternalAuth = 'OverInternalAuth';

    public static readonly MissingResponseData = 'Missing response data error';

    public static readonly FalloutMissingLogOrSource = 'Missing Log Type or Source Type';

    public static readonly ZeroRateModifierCannotLinkQualifications = '$0.00 rate differentials may not have qualifications linked to them';

    public static readonly RateModifierGTZeroMustHaveQualification =
      'Rate differentials greater than $0.00 must have greater than or equal to one qualification linked to them';

    public static readonly EcnIsRequired = 'ECN cannot be empty for payments.';

    public static readonly adjustmentReasonNotFoundCleared = 'Adjustment reason not-found/cleared';
    public static readonly adjustmentNotFoundCleared = 'Adjustment not-found/cleared';

    public static readonly NoExternalKeyFoundForRole = 'You do not have permissions to manage external keys';

    public static readonly AllAvailableExternalKeysAdded = 'All available external keys are already configured';

    public static readonly RelationshipDateRangeChangeSuccess = 'Caregiver Participant Date Range modified successfully!';
    public static readonly RelationshipDateRangeChangeFailure = 'Caregiver Participant Date Range modification failed.';

    public static readonly LiveinDateRangeChangeSuccess = 'Caregiver Participant Live-In Date Range modified successfully!';
    public static readonly LiveinDateRangeChangeFailure = 'Caregiver Participant Live-In Date Range modification failed.';

    public static readonly CrmKeyNotFound = 'No CRM key found';

    public static readonly RemarkCodeDeleteSelectError = 'Please select at least one Remark Code to delete';

    public static readonly RemarkCodeDeleteActivetError = 'Only Active Remark Code can be deleted';

    public static readonly RemarkCodeDeleteSuccess = 'Remark Code(s) deleted successfully!';

    public static readonly RemarkCodeAddedSuccess = 'Remark Code added successfully!';

    public static readonly RemarkCodeUpdatedSuccess = 'Remark Code Updated successfully!';

    public static readonly exportSuccessMessage = 'You will be notified over email when the file is ready for download';

    public static readonly noClaimsExistMesssage = 'No Claims exist matching the search criteria';

    public static readonly invalidEmailForOnboard = 'This action cannot be completed without a valid email address';

    public static Required(field: string): string {
        return `${field} is required.`;
    }

    public static LimitExceeded(field: string, length: number): string {
        return `${field} has exceeded maximum length of ${length}.`;
    }

    public static AtLeastOneRequired(field: string): string {
        return `At least one ${field} is required.`;
    }

    public static NotFound(field: string): string {
        return `${field} not found.`;
    }

    public static NoItemsSelected(field: string): string {
        return `No ${field} are selected.`;
    }

    public static InvalidFieldMessage(field: string): string {
        return `${field} not found/invalid.`;
    }

    public static readonly CourseDeleteSelectError = 'Please select at least one course to delete';

    public static readonly CourseCodeDeleteSuccess = 'Course(s) deleted successfully!';

    public static readonly SystemEventPlaceholders = 'One or more Placeholder {{ }} is invalid for System Event';

    public static readonly NewUsageRecordOverlap = 'New Record Overlaps With Existing Service Code Usage Limit';

    public static readonly UpdatedUsageRecordOverlap = 'Updated Record Overlaps With Existing Service Code Usage Limit';

    public static readonly SubRuleDeleteSelectError = 'Please select at least one sub rule to delete';

    public static readonly SubRuleDeleteSuccess = 'Sub rule(s) deleted successfully!';

    public static readonly StagedRoleVerifyFail = ' failed to save, role type does not have any qualifications.';

    public static readonly StagedRoleSaveFail = 'Staged Role(s) failed to save. Please contact system administrator';

    public static readonly StagedQualSaveFail = 'Staged Qualification(s) failed to save. Please contact system administrator';

    public static readonly PaymentBatchExportError = 'At least 1 payment batch must be selected';

    public static readonly CaregiverCommentsInvalid = 'The maximum length of Caregiver comments is 500 characters';

    public static readonly ClaimBatchExportError = 'At least 1 claim batch must be selected';
}
