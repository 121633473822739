import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import * as msal from '@azure/msal-browser';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { HeaderComponent } from '@progress/kendo-angular-dateinputs';
import { UserIdleService } from 'angular-user-idle';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, take } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { StorageConstants } from 'src/app/shared/constants/storage-constants';
import { environment } from './../environments/environment';
import { AppLoaderService } from './controllers/app/loaders.controller';
import { AppConstants } from './core/appConstant';
import { AppInsightsService } from './core/services/app-insights.service';
import { BillingFunctionService } from './core/services/billingapi/billingfunctionapi.services';
import { CommonFunctions } from './shared/utilities/common-functions';
import { UnloadPersonsTypeSearchCriteria } from './u2-person/person-state/person.actions';
import { IamWrapperService } from './core/services/iam-wrapper.service';
import { UnauthorizeService } from './core/services/unauthorize.service';
import { FeatureStateService } from './shared/services/featureStateService';
import { HierarchicalPermissionsService } from './core/services/hierarchical-permissions.service';

@Component({
  selector: 'CDMS-Portal-U2-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy  {

  private pingSubscription: Subscription;
  title = AppConstants.AppTitle;
  public userName: string;
  isAuthenticated = true;
  isEmptyRoute = true;
  sideNavCollapse = false;
  userHasNoPermissions = false;
  showSideNavContent = false;
  sessionExpires = false;
  public url;
  public busyLoader = false;
  companyCode: any;
  showRouterOutlet = true;
  latestCompanyAccess: any;
  public appConst;
  refreshTokenInterval: any;
  firstmenuUrl: string;
  token = '';
  private readonly _destroying$ = new Subject<void>();
  @ViewChild('header') header: HeaderComponent;

  constructor(
    private readonly http: HttpClient,
    private readonly msalService: MsalService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly appInsightsService: AppInsightsService,
    private readonly userIdle: UserIdleService,
    private readonly sanitizer: DomSanitizer,
    private readonly billingFunctionService: BillingFunctionService,
    private readonly store: Store<any>,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly appLoaderService: AppLoaderService,
    public toastr: ToastrService,
    private readonly commonFunctions: CommonFunctions,
    private readonly iamWrapperService: IamWrapperService,
    private readonly unauthorizeService: UnauthorizeService,
    private readonly featureStateService: FeatureStateService,
    private readonly hierarchicalPermissionService: HierarchicalPermissionsService
    ) {
    const redirectUrl = localStorage.getItem(StorageConstants.RedirectUri);
    if (redirectUrl === '' || !redirectUrl) {
      localStorage.setItem(StorageConstants.RedirectUri, window.location.href);
    }
    this.busyLoader = true;
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      takeUntil(this._destroying$)
    )
    .subscribe({
      next: (result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
        this.busyLoader = true;
        this.token = payload.accessToken;
        this.appInsightsService.userInfo.user = JSON.stringify(payload.account.idTokenClaims?.oid);
        this.userName = payload.account.name;
        localStorage.setItem('UserName', this.userName);
       const account: msal.AccountInfo = this.msalService.instance.getAllAccounts()[0];
        localStorage.setItem('accountInfo',JSON.stringify(account));
        this.isAuthenticated = true;
        if (this.header) {
          this.header.ngOnInit();
        }
      },
      error: error => {
        this.isAuthenticated = false;
        this.commonFunctions.handleErrorResponse(error, this.toastr);
      }
    });

    this.configureIdealTimeOut();
    this.appConst = AppConstants;
    this.setAppLoader();
  }

  get IsCompanyContextExistOrUserUnauthorized() {
    return this.iamWrapperService.UserAccessedCompany || this.unauthorizeService.isUnauthorized;
  }

  ngOnInit() {
    this.getCurrentDomainSessionStorage();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isEmptyRoute = (event.url === '/');
        this.disposeSessionStorage(event);
        if (this.isEmptyRoute) {
          this.appInsightsService.logPageView(document.title, this.router.url, { url: document.URL });
        }
      }
    });
  }
  sideNavChangeClick(value) {
    this.sideNavCollapse = value;
  }
  userHasNoAccessEvent(eventData) {
    this.userHasNoPermissions = eventData;
    this.showSideNavContent = !this.userHasNoPermissions;
    this.busyLoader = false;
    this.showRouterOutlet = true;
  }
  close(option: string) {
    if (option === 'logout') {
      this.sessionExpires = false;
      if(this.featureStateService.useIamApis()) {
        this.clearCache();
      }
      else {
        this.logout(null);
      }
    }
  }

  clearCache() {
    this.busyLoader = true;
    this.iamWrapperService.clearUserCache()
      .pipe(
        take(1),
        takeUntil(this._destroying$)
      ).subscribe({
        next: (data: any) => {
          this.logout(null);
        },
        error: error => {
          this.logout(null);
        }
      });
  }

  configureIdealTimeOut(): void {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe();
    this.userIdle.onTimeout().subscribe(() => {
      this.sessionExpires = true;
      this.restart();
    });

    this.pingSubscription = this.userIdle.ping$
      .subscribe(() => {
        this.updateUserSession();
      });
  }

  updateUserSession() {
    return this.http.get<any>(AppConstants.updateUserSession, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
    this.pingSubscription.unsubscribe();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  async logout(eventData) {
    this.busyLoader = true;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(AppConstants.LogoutMSALADURL + window.location.href);
    await this.msalService.logoutRedirect({
      authority: environment.authority + environment.tenant,
      account: this.msalService.instance.getAllAccounts()[0],
      postLogoutRedirectUri: '/',
      onRedirectNavigate: () =>
        // Return false to stop navigation after local logout
         false

  });

  }
  onFrameLoad() {
    if (this.url && this.url !== null) {
      sessionStorage.clear();
      localStorage.clear();
      this.isAuthenticated = false;
      this.busyLoader = false;
      window.location.href = window.location.origin;
    }
  }
  getFirstMenuUrl(eventData) {
    this.firstmenuUrl = '/' + eventData;
  }
  companyCodeChange(eventData) {
    this.busyLoader = true;
    this.showRouterOutlet = false;
    this.companyCode = eventData.companyData;
    this.store.dispatch(new UnloadPersonsTypeSearchCriteria());
    const currentRoute = this.router.url;
    if (eventData.isCompanyChange && this.firstmenuUrl === currentRoute) {
      window.location.reload();
    }
    if (this.iamWrapperService.UseIamApis && this.featureStateService.isU2UseHierarchicalPermissionEnabled()) {
      if(eventData.isCompanyChange && this.firstmenuUrl !== currentRoute) {
        this.getUserAssignedHierarchicalPermissions();
      }
    }
  }

  getUserAssignedHierarchicalPermissions() {
    this.commonFunctions.showLoading();
    this.iamWrapperService.getUserAssignedHierarchicalPermissions().subscribe({
      next: permissions => {
        if(permissions) {
          this.hierarchicalPermissionService.userPermissions = permissions;
          this.refreshRoute();
          this.commonFunctions.hideLoading();
        }
      },
      error: error => {
        this.commonFunctions.hideLoading();
        this.commonFunctions.handleErrorResponse(error, this.toastr);
      }
    });
  }

  refreshRoute() {
    const currentUrl = this.router.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  addedLastCompanyEvent(eventData) {
    this.latestCompanyAccess = eventData;
  }

  disposeSessionStorage(event) {
    if (event.url) {
      const url = event.url.toLowerCase();
      if (url.indexOf('customeremployeerelation') === -1) {
        sessionStorage.removeItem('ClientSelectedRelation');
        sessionStorage.removeItem('EmployeeSelectedRelation');
      }
      if (url.indexOf('payrate') === -1) {
        sessionStorage.removeItem('ClientSelected');
        sessionStorage.removeItem('EmployeeSelected');
      }
      if (url.indexOf('shiftmaintenance') === -1) {
        sessionStorage.removeItem(StorageConstants.ShiftMaintenanceSearch);
      }
      if (url.indexOf('billrates') === -1) {
        sessionStorage.removeItem('PayerSelected');
      }
      if (url.indexOf('caregiverpayrates') === -1) {
        sessionStorage.removeItem('ClientSelectedRates');
        sessionStorage.removeItem('CaregiverEmployerSelected');
        sessionStorage.removeItem('IsCgvEmpRelationshipComboBoxEnabled');
      }
      if (url.indexOf('servicecodes/edit') !== -1
        || url.indexOf('servicecodes/editrate') !== -1
        || url.indexOf('servicecodes/addrate') !== -1) {
        return;
      } else {
        sessionStorage.removeItem('ServiceCodeValues');
        sessionStorage.removeItem('ServiceCodeValuesDeleted');
      }
      if (url.indexOf('servicecodes') === -1) {
        sessionStorage.removeItem(StorageConstants.ServiceCodeSearch);
      }

      if (url.indexOf('participantinsurance') === -1) {
        sessionStorage.removeItem(StorageConstants.CISearch);
      }
      if (url.indexOf('externalcasemanagers') === -1) {
        sessionStorage.removeItem(StorageConstants.EcmSearch);
      }
      if (url.indexOf('payor') === -1) {
        sessionStorage.removeItem(StorageConstants.PayorSearch);
      }
      if (url.indexOf('program') === -1) {
        sessionStorage.removeItem(StorageConstants.ProgramSearch);
      }
      if (url.indexOf('writeoffcause') === -1) {
        sessionStorage.removeItem(StorageConstants.WriteOffCauseSearch);
      }
      if (url.indexOf('feetype') === -1) {
        sessionStorage.removeItem(StorageConstants.feeTypeSearch);
      }
      if (url.indexOf('fundingtype') === -1) {
        sessionStorage.removeItem(StorageConstants.fundingTypeSearch);
      }
      if (url.indexOf('claimsummary') === -1) {
        sessionStorage.removeItem(StorageConstants.ClaimSummaryInquirySearch);
      }
      if (url.indexOf('feerate') === -1) {
        sessionStorage.removeItem(StorageConstants.FeeRateSearch);
      }

      if (url.indexOf('claimbatchreview') === -1) {
        sessionStorage.removeItem(StorageConstants.ClaimBatchSearch);
      }

      if (url.indexOf('payroll/review') === -1) {
        sessionStorage.removeItem(StorageConstants.PayrollBatchReviewRunId);
        sessionStorage.removeItem(StorageConstants.PayrollBatchReviewSystem);
        sessionStorage.removeItem(StorageConstants.PayrollBatchReviewStart);
        sessionStorage.removeItem(StorageConstants.PayrollBatchReviewEnd);
        sessionStorage.removeItem(StorageConstants.PayrollBatchReviewCaregiver);
        sessionStorage.removeItem(StorageConstants.PayrollBatchReviewPayor);
        sessionStorage.removeItem(StorageConstants.PayrollBatchReviewIsAbsence);
        sessionStorage.removeItem(StorageConstants.PayrollBatchReviewIsTimeWorked);
      }

      if (url.indexOf('programtypes') === -1) {
        sessionStorage.removeItem(StorageConstants.ProgramTypeSearch);
      }

      if (url.indexOf('fundingsource') === -1) {
        sessionStorage.removeItem(StorageConstants.FundingSourceSearch);
      }
      if (url.indexOf('diagcode') === -1) {
        sessionStorage.removeItem(StorageConstants.DiagCodeSearch);
      }
      if (url.indexOf('participantrepresentativerelations') === -1) {
        sessionStorage.removeItem(StorageConstants.ClientRepSelectedRelation);
        sessionStorage.removeItem(StorageConstants.RepresentativeSelectedRelation);
      }
      if (url.indexOf('authorizations') === -1) {
        sessionStorage.removeItem(StorageConstants.AuthorizationsSearch);
      }
    }
  }

  public onAuthorizationChangeConfirmationDataItem(event) {
    AppConstants.showConformation = false;
    if (event) {
      AppConstants.formDirty = false;
      this.router.navigateByUrl(AppConstants.currentRouteUrl);
    }
  }

  public getCurrentDomainSessionStorage() {
    // trigger storage event on new tab
    if (sessionStorage.getItem('screenpermssions') === null) {
      window.localStorage.setItem('shareSessionStorageData', Date.now().toString());
    }
    window.addEventListener('storage', function(event) {
      if (event.key === 'shareSessionStorageData') {
        localStorage.setItem('sessionStorageFromOpenTab', JSON.stringify(sessionStorage));
        localStorage.removeItem('sessionStorageFromOpenTab');
      } else if (event.key === 'sessionStorageFromOpenTab' && sessionStorage.getItem('screenpermssions') === null) {
        // copy session storage from existing tab to new tab
        const data = JSON.parse(event.newValue);
        Object.keys(data).forEach((key) => {
          sessionStorage.setItem(key, data[key]);
        });
        localStorage.removeItem('shareSessionStorageData');
      }
    });
  }

  public setAppLoader() {
    this.appLoaderService.getLoader().pipe(takeUntil(this._destroying$))
    .subscribe((data) => {
      if (data !== undefined) {
        this.busyLoader = Object.values(data).find(item => item === true) !== undefined;
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
