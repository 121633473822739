import { Injectable } from '@angular/core';
import { HierarchicalPermissionDto } from './usersapi/usersapi.services';

@Injectable({
  providedIn: 'root'
})
export class HierarchicalPermissionsService {

  public userPermissions: HierarchicalPermissionDto;

  constructor() { }
}
